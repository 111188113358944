<template>
  <div class="text-center">
    <k-title>{{ $t('errors.noPermission') }}</k-title>
    <div class="my-12 title">
      {{ $t('errors.403') }}
    </div>
    <v-btn
      tile
      depressed
      color="primary"
      to="/"
    >
      {{ $t('errors.toTheDashboard') }}
    </v-btn>
  </div>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import KTitle from '@/components/layout/KTitle.vue';

export default {
  name: 'PageForbidden',
  components: { KTitle },
  created() {
    eventBus.$emit('setBreadcrumbs', []);
  },
};
</script>
